import React from 'react';
import PropTypes from 'prop-types';
import { Cushion, Grid, Rectangle, Shadow, Typography } from '@pitchero/react-ui';
import { DateTime } from 'luxon';
import StripedBar from 'components/striped-bar';

const StatusBox = ({
  awayScore,
  className,
  date,
  elapsedPercent,
  fillColor: fillCoverOverride,
  homeScore,
  kickoff,
  hasOutcome,
  isLive,
  isCancelledOrPostponed,
  outcome,
  style,
}) => {
  let content;
  let fillColor;
  const color = 'white';

  if (hasOutcome || isLive || isCancelledOrPostponed) {
    if (isLive) {
      fillColor = 'black';
    } else {
      fillColor = outcome ? `outcomeBg${outcome.toUpperCase()}` : 'outcomeBgD';
    }
    content = (
      <Grid columns="1fr 10px 1fr" className="statusbox__scores">
        <Typography
          preset="name"
          color={color}
          component="div"
          align="center"
          className="statusbox__scores"
        >
          {isCancelledOrPostponed ? outcome : homeScore}
        </Typography>
        <Typography preset="name" color={color} component="div" align="center">
          -
        </Typography>
        <Typography
          preset="name"
          color={color}
          component="div"
          align="center"
          className="statusbox__scores"
        >
          {isCancelledOrPostponed ? outcome : awayScore}
        </Typography>
      </Grid>
    );
  } else {
    fillColor = 'black';
    if (date || kickoff) {
      content = (
        <>
          <Typography preset="tag--small" color="dustygrey" align="center" component="div">
            {date && DateTime.fromISO(date).toFormat('ccc d')}
          </Typography>
          <Typography preset="name" color="white" align="center" component="div">
            {kickoff}
          </Typography>
        </>
      );
    } else {
      content = (
        <>
          <Typography preset="name" color="alto" align="center" component="div">
            -
          </Typography>
        </>
      );
    }
  }

  return (
    <Shadow shadow="dark">
      <Rectangle
        fill={fillCoverOverride || fillColor}
        radius="rounded"
        style={{ overflow: 'hidden', ...style }}
        className={className}
      >
        <Cushion vertical="xsmall" component="div" className="statusbox ">
          {content}
        </Cushion>
        {isLive && (
          <Rectangle fill="black">
            <StripedBar
              background="#9ae4ca"
              foreground="#41c894"
              height={6}
              style={{ borderRadius: '0 4px 4px 4px', width: `${elapsedPercent || 100}%` }}
            />
          </Rectangle>
        )}
      </Rectangle>
    </Shadow>
  );
};

StatusBox.defaultProps = {
  awayScore: null,
  className: null,
  date: null,
  elapsedPercent: 0,
  fillColor: null,
  hasOutcome: false,
  homeScore: null,
  isLive: false,
  isCancelledOrPostponed: false,
  kickoff: null,
  outcome: null,
  style: {},
};

StatusBox.propTypes = {
  awayScore: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  date: PropTypes.string,
  elapsedPercent: PropTypes.number,
  fillColor: PropTypes.string,
  hasOutcome: PropTypes.bool,
  isLive: PropTypes.bool,
  isCancelledOrPostponed: PropTypes.bool,
  homeScore: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  kickoff: PropTypes.string,
  outcome: PropTypes.string,
  style: PropTypes.shape(),
};

export default StatusBox;

import React from 'react';
import PropTypes from 'prop-types';
import { Cushion, Rectangle, Shadow, Typography } from '@pitchero/react-ui';
import { DateTime } from 'luxon';
import { Trans } from 'lib/i18n';

const CricketStatusBox = ({ date, kickoff, hasOutcome, outcome, style }) => {
  let content;
  let fillColor;
  const color = 'white';

  if (hasOutcome) {
    fillColor = outcome ? `outcomeBg${outcome.toUpperCase()}` : 'outcomeBgD';
    content = (
      <Typography preset="name" color={color} component="div" align="center">
        <Trans i18nKey={`teams:outcome_${outcome.toLowerCase()}`}>{outcome}</Trans>
      </Typography>
    );
  } else {
    fillColor = 'black';
    content = (
      <>
        <Typography preset="tag--small" color="dustygrey" align="center" component="div">
          {DateTime.fromISO(date).toFormat('ccc d')}
        </Typography>
        <Typography preset="name" color="white" align="center" component="div">
          {kickoff}
        </Typography>
      </>
    );
  }

  return (
    <Shadow shadow="dark">
      <Rectangle fill={fillColor} radius="rounded" style={style}>
        <Cushion vertical="xsmall" component="div">
          {content}
        </Cushion>
      </Rectangle>
    </Shadow>
  );
};

CricketStatusBox.defaultProps = {
  date: null,
  hasOutcome: false,
  kickoff: null,
  outcome: null,
  style: {},
};

CricketStatusBox.propTypes = {
  date: PropTypes.string,
  hasOutcome: PropTypes.bool,
  kickoff: PropTypes.string,
  outcome: PropTypes.string,
  style: PropTypes.shape(),
};

export default CricketStatusBox;

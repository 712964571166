import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

const moveAnimation = keyframes`
  from { background-position: 0 0; },
  to { background-position: 30px 0; },
`;

const StripedBar = styled.div`
  background-image: linear-gradient(
    135deg,
    ${(props) => props.foreground} 25%,
    ${(props) => props.background} 25%,
    ${(props) => props.background} 50%,
    ${(props) => props.foreground} 50%,
    ${(props) => props.foreground} 75%,
    ${(props) => props.background} 75%,
    ${(props) => props.background}
  );
  background-size: 30px 30px;
  background-position: 30px 0;
  animation: ${moveAnimation} 1s linear infinite;
  height: ${(props) => props.height}px;
`;

StripedBar.defaultProps = {
  background: 'transparent',
  foreground: 'rgba(255, 255, 255, .15)',
  height: 8,
  style: {},
};

StripedBar.propTypes = {
  background: PropTypes.string,
  foreground: PropTypes.string,
  height: PropTypes.number,
  style: PropTypes.shape(),
};

export default StripedBar;
